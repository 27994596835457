:root{
  --Navbar-container-width: 20vw;
  --Header-height: 10vh;
  --Navbar-left-margin: 18%;
 }

 /* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--bg-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--low-opacity-primary-color);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color)
}

.App{
  background-color: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Nixie One";
  text-decoration: none;
  overflow-x: hidden;
}

.Main{
 background-color: none;
 display: flex;
 flex-direction: row;
 width: 100%;
 height: 100%;
}

.Navigate-Menu{
  background-color: none;
  position: fixed;
  left: 0;
  width: 20vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  z-index: 3;
}

.Navigate-Menu:hover .leftsemiCircle-container{
  transform: translate(-470%)
}

.Content-container{
  background-color: none;
  height:100%;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  margin-left: var(--Navbar-container-width);
}

.Line-container{
  background-color: none;
  position: fixed;
  right: 0;
  width: 20vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  z-index: 3;
}

.Line-container:hover .semiCircle-container{
  transform: translate(-130%);
}

.Left-arrow{
  position: absolute;
  left: 10%;
  color:var(--primary-color);
  top: 47.5%;
  transition: 0.5s;
  font-size: 2vw;
  margin: none;
  font-weight: bold;
}

.Navigate-Menu:hover .Left-arrow{
  opacity: 0%;
  animation: fadeout 0.4s 1
}

.Right-arrow{
  position: absolute;
  right: 10%;
  color: var(--primary-color);
  top: 47.5%;
  transition: 0.5s;
  font-size: 2vw;
  margin: none;
  text-align: right;
  font-weight: bold;
}


@keyframes fadeout{
  0% {opacity: 75%;}
  100% {opacity: 0%;}
}

@keyframes fadein{
  0% {opacity: 0%;}
  100% {opacity: 75%;}
}

.Line-container:hover .Right-arrow{
  opacity: 0%;
  animation: fadeout 0.5s 1;
}

.Subheading{
  margin: 0px;
  margin-top: 3;
  font-size: 5vw;
  color: var(--primary-color);
  font-weight: lighter;
  /* border: 1px solid orange; */
  padding-top: 5vh;
}

/* Phone Sizing */
@media only screen and (max-width: 600px) {
  :root{
    --Navbar-container-width: 15vw;
    --Header-height: 10vh;
  }

  .Main{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Line-container{
    display:none;
    width: 0vw;
    height: 0vh;
  }

  .Content-container{
    width: 85vw;
    margin-left: 0%;
  }

  .Navigate-Menu{
    display:none;
    width: 0vw;
    height: 0vh;
  }

  .Subheading{
    margin: 0px;
    margin-top: 3%;
    font-size: 10vw;
    color: var(--primary-color);
    font-weight: lighter;
}

}