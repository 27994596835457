.PortfolioPage{
    width: 85%;
    height: 100vh;
    background-color: none;
    margin-top: 45vh;
    margin-left:10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2%;
    min-height: 400px;
    /* border: 1px solid white; */
}

.Portfolio-grid{
    margin-top: 2%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 3%;
    grid-row-gap: 3%;
    /* border: 1px solid blue; */
}


/* Phone Sizing */
@media only screen and (max-width: 600px) {
    .PortfolioPage{
        width: 100%;
        height: 100vh;
        background-color: none;
        margin-top: 0;
        margin-left:0%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 2%;
        min-height: 800px;
        /* border: 1px solid white; */
    }

    .Portfolio-grid{
        margin-top: 2%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 3%;
        grid-row-gap: 3%;
        /* border: 1px solid blue; */
    }
}