.ExperiencePage{
    width: 85%;
    height: 100vh;
    background-color: none;
    margin-top: 45vh;
    margin-left:10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2%;
    min-height: 400px;
    /* border: 1px solid orange; */
}

.Experience-container{
    display: flex; 
    /* border: 1px solid green;  */
    align-items: center;
    justify-content: flex-start;
    padding-top: 1%;
    padding-bottom: 1%;
    height: 27.5vw;
    margin-top: 3%;
}

.Experience-button-container{
    /* border: 1px solid blue; */
    width: 25%;
    height: 100%;
    padding-top: 1%;
    padding-bottom: 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.Experience-button{
    cursor: pointer;
    height: 10%;
    background-color: var(--bg-color);
    border: 2px solid var(--primary-color);
    border-radius: 15px 0 0 15px;
    color:var(--primary-color);
    transition: 0.3s;
    border-right: none;
    font-family: inherit;
    font-size: 1.2vw;
}

.Experience-button:hover{
    background-color: var(--primary-color);
    color:var(--bg-color);
}

.Active-button{
    background-color:var(--primary-color);
    color:var(--bg-color);
}

.Experience-text-container{
    border: 2px dashed var(--primary-color);
    width: 65%;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;
    border-radius: 2%;
    transition: 0.3s;
    /* height: 100%; */
}

.Experience-text-header{
    color:var(--primary-color);
    /* border: 1px solid blue; */
    font-size: 1.8vw;
}

.Experience-date{
    color:  var(--secondary-color);
    /* border: 1px solid green; */
    font-size: 1.5vw;
}

.Experience-text{
    color:  var(--secondary-color);
    /* border: 1px solid red; */
    font-size: 1.4vw;
    font-weight:bold;
    padding: 0;
}




/* Phone Sizing */
@media only screen and (max-width: 600px) {
    .ExperiencePage{
        width: 100%;
        height: 100vh;
        background-color: none;
        margin-top: 0;
        margin-left:0%;
        display: flex;
        flex-direction: column;
        justify-content:flex-start;
        padding-top: 2%;
        min-height: 800px;
        /* border: 1px solid orange; */
    }
    
    .Experience-container{
        display: flex; 
        flex-direction: column;
        /* border: 1px solid green;  */
        align-items: center;
        justify-content: flex-start;
        padding-top: 1%;
        padding-bottom: 1%;
        height: 60%;
        margin-top: 3%;
    }
    
    .Experience-button-container{
        /* border: 1px solid blue; */
        width: 100%;
        height: 5%;
        padding-top: 0%;
        padding-bottom: 0%;
        margin-top: 2%;
        margin-bottom: 2%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
    
    .Experience-button{
        cursor: pointer;
        height: 100%;
        width: 45%;
        background-color: var(--bg-color);
        border: 1px solid var(--primary-color);
        border-radius: 15px;
        color:var(--primary-color);
        transition: 0.3s;
        border-right: none;
        font-family: inherit;
        font-weight: bold;
        font-size: 2.5vw;
        padding:0;
        text-align: center;
        align-items: center;
        margin: 0%;
    }
    
    .Experience-button:hover{
        background-color: var(--primary-color);
        color:var(--bg-color);
    }
    
    .Active-button{
        background-color:var(--primary-color);
        color:var(--bg-color);
    }
    
    .Experience-text-container{
        border: 2px dashed var(--primary-color);
        width: 90%;
        padding-top: 1%;
        padding-bottom: 1%;
        padding-left: 3%;
        padding-right: 3%;
        border-radius: 2%;
        transition: 0.3s;
        height: 65%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .Experience-text-header{
        color:var(--primary-color);
        /* border: 1px solid blue; */
        font-size: 3.5vw;
    }
    
    .Experience-date{
        color:  var(--secondary-color);
        /* border: 1px solid green; */
        font-size: 3vw;
    }
    
    .Experience-text{
        color:  var(--secondary-color);
        /* border: 1px solid red; */
        font-size: 3vw;
        font-weight:bold;
    }
}