.Portfolio-card{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 30px;
    align-items: center;
}

.Portfolio-card:before{
    content: "";
    display: block;
    padding-top: 100%;
}

.card-overlay{
    position: absolute;
    border: 2px solid var(--primary-color);
    width: 99%;
    height:99%;
    background-color: var(--primary-color);
    color: var(--bg-color);
    opacity: 0%;
    transition: 0.4s;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.5vw;
    z-index: 1;
    text-align: center;
    font-weight: bold;
}

.card-overlay:hover{
    opacity: 85%;
}

.card-overlay-title{
    font-size: 1.8vw;
    font-weight: bolder;
}

.icon-container{
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
}

.Icon-button{
    margin-top: 2%;
    margin-bottom: 0%;
    border-radius: 15px;
    transition: 0.2s;
    border: 1px solid var(--bg-color);
    background-color: var(--primary-color);
    color: var(--bg-color);
    cursor: pointer;
    font-size: 1.2vw;
    width: 45%;
    padding: 1%;
    text-decoration: none;
    font-weight: bolder;
}

.Icon-button:hover{
    background-color: var(--bg-color);
    color: var(--primary-color);
}

.Github-icon:hover{
    cursor: pointer;
}
    
.Link-icon:hover{
    cursor: pointer;   
}

.Portfolio-image{
    position: relative;
    width:100%;
    border-radius: 30px;
    /* border: 1px solid yellow; */
    border: 2px solid var(--primary-color);
    z-index: 0;
    opacity: 80%;
    background-color: var(--primary-color);
}

/* Phone Sizing */
@media only screen and (max-width: 600px) {
    .Portfolio-card{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        border-radius: 30px;
    }
    
    .Portfolio-card:before{
        content: "";
        display: block;
        padding-top: 100%;
    }
    
    .card-overlay{
        position: absolute;
        border: 2px solid var(--primary-color);
        width: 99%;
        height:99%;
        background-color: var(--low-opacity-primary-color);
        transition: 0.4s;
        opacity: 100%;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        font-size: 3vw;
        z-index: 1;
        text-align: center;
        font-weight: bold;
    }

    .card-overlay-title{
        font-size: 4vw;
    }

    .icon-container{
        width: 80%;
        height: 20%;
        display: flex; 
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    
    .Icon-button{
        margin-top: 2%;
        margin-bottom: 2%;
        border-radius: 15px;
        transition: 0.2s;
        border: 1px solid var(--bg-color);
        background-color: var(--primary-color);
        color:var(--bg-color);
        cursor: pointer;
        width: 80%;
        height: 50%;
        font-size: 2.5vw;
    }
    
    .Icon-button:hover{
        background-color:var(--bg-color);
        color: var(--primary-color);
    }
    
    .Github-icon:hover{
        cursor: pointer;
    }
        
    .Link-icon:hover{
        cursor: pointer;   
    }
    
    .Portfolio-image{
        position: relative;
        width:100%;
        border-radius: 30px;
        /* border: 1px solid yellow; */
        border: 2px solid var(--primary-color);
        z-index: 0;
        opacity: 80%;
        background-color: var(--primary-color);
    }
}