/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 64px;
    height: 38px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--primary-color);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--primary-color);
    border: 2px solid var(--bg-color);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-color: var(--bg-color);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    border: 2px solid var(--primary-color);
  }
  
  .slider.round:before {
    border-radius: 50%;
  }