.Aboutmepage{
    width:95%;
    height: 100vh;
    min-height: 400px;
    background-color: none;
    margin-top: 45vh;
    margin-bottom: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-right: 20vw; */
    padding-top: 2%;
    /* border: 1px solid blue; */
}

.Aboutme-container{
    display: flex; 
    justify-content: space-evenly;
    /* border: 1px solid orange;  */
    align-items: center;
    max-height: 100%;
    padding: 1%;
    padding-top: 2%;
    padding-bottom: 2%;
}

.Abouttext-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 60%;
    color: var(--secondary-color);
    /* border: 1px solid green; */
    min-height: 90%;
    height: fit-content;
    padding-right: 5%;
    font-weight: bold;
    padding: 3%;
    padding-top: 1%;
}

.Profile-card{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 33%;
    border: 2px solid var(--primary-color);
    border-radius: 15px;
    padding: 2%;
    height: 100%;
}

.Abouttext{
    /* border: 1px solid red; */
    font-size: 1.2vw;
    margin: 0;
    text-align: justify;
    
}

.Skills-container{
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.Skillstext{
    /* border: 1px solid red; */
    font-size: 1.8vw;
    font-weight: bold;
    color: var(--primary-color);
    text-align: center;
    margin: 0;
    margin-top: 5%;
    margin-bottom: 2%;
}

.Tech-list-container{
    /* border: 1px solid white; */
    display: flex;
    height: 100%
}

.Tech-list{
    /* border: 1px solid hotpink; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;

}

.Tech {
    text-align: center;
    font-size: 1.3vw;
    transition: 0.3s;
    margin: 0;
    color: var(--secondary-color);
    font-weight: bold;
    margin-bottom: 15%;
  }

.Icon{
    color: var(--primary-color)
}


.Profile-picture{
    width: 70%;
    height: 70%;
    object-fit: scale-down;
    clip-path: circle();
    margin-top: 5%;
    margin-bottom: 5%;
    background-color: var(--primary-color);
}

.noah{
    color: var(--secondary-color);
    font-size: 2vw;
    margin: 0;
    margin-top: 5%;
    margin-bottom: 5%;
    font-weight: bold;
}

/* Phone Sizing */
@media only screen and (max-width: 600px) {
    .Aboutmepage{
        width:100%;
        height: fit-content;
        background-color: none;
        margin-top: 45vh;
        margin-left:0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 0%;
        padding-top: 2%;
        min-height: 800px;
        /* border: 1px solid blue; */
    }
    
    .Aboutme-container{
        display: flex; 
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        /* border: 1px solid orange;  */
        height: 100%;
        padding: 0;
        padding-top: 2%;
        padding-bottom: 1%;
        
    }
    
    .Abouttext-container{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 4%;
        width: 100%;
        color: var(--secondary-color);
        /* border: 1px solid green; */
        height: 40%;
        padding: 0%;
        font-weight: bold;
    }
    
    .Profile-card{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 60%;
        border: 2px solid var(--primary-color);
        border-radius: 15px;
        padding: 2%;
        height: 60%
    }
    
    .Abouttext{
        /* border: 1px solid red; */
        font-size: 3.3vw;
        margin: 0;
        text-align: justify;
        border: 1px solid var(--primary-color);
        padding: 3%;
        border-radius: 15px;
    }
    
    .Skills-container{
        /* border: 1px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
    }
    
    .Skillstext{
        /* border: 1px solid red; */
        font-size: 4vw;
        color: var(--primary-color);
        text-align: center;
        margin: 0;
        margin-top: 1%;
        margin-bottom: 8%;
    }
    
    .Tech-list-container{
        /* border: 1px solid white; */
        display: flex;
        height: 100%
    }
    
    .Tech-list{
        /* border: 1px solid hotpink; */
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 50%;
    
    }
    
    .Tech {
        list-style: none; /* Remove default bullets */
        text-align: center;
        font-size: 2.9vw;
        transition: 0.3s;
        margin: 0;
        margin-bottom: 20%;
      }

    .Profile-picture{
        max-width: 50%;
        max-height: 50%;
        object-fit: scale-down;
        clip-path: circle();
        margin-top: 2%;
        margin-bottom: 2%;
        background-color: var(--primary-color);
    }
    
    .noah{
        color: var(--secondary-color);
        font-size: 150%;
        margin: 0;
        margin-top: 2%;
        margin-bottom: 2%;
        font-weight: bold;
    }
}
