
.semiCircle{
    height: 20vh; 
    width: 400%;
    transform: translate(30%);
    border-radius: 150px 0px 0px 150px;
    background-color: var(--primary-color);
    transition: 0.5s;
    opacity: 50%;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    justify-content: center;
    font-family: inherit;
    font-size: 1vw;
    color:var(--bg-color);
    font-weight: bold;
    text-decoration: none;
  }
  
  .semiCircle:hover{
    transform: translate(-40%);
    opacity:100%;
    font-size: 2vw;
  }

  .semiCircle-container{
    border: none;
    width: 25%;
    transform: translate(25%);
    transition: 0.5s;
  }