body {
  --bg-color: #000515;
  --primary-color: #ff60d4;
  --low-opacity-primary-color: #ff60d5c6;
  --secondary-color: #F3D1FF;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  transition: 0.5s;
}

.light-mode{
  --bg-color: #f8e5ff;
  --primary-color: #5f00a2;
  --low-opacity-primary-color: #5e00a2cf;
  --secondary-color: #36005c;
  transition: 0.5s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
 }

