.Landingpage{
    width:85%;
    height: 100vh;
    background-color: none;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 20vw;
    padding-top: 2%;
    min-height: 400px;
    /* transition: background-color 0.3s; */
    /* border: 1px solid blue; */
}

.Heading{
    margin: 0px;
    font-size: 6vw;
    color:  var(--secondary-color);
    font-weight: lighter;
}

.Heading-Developer{
    margin: 0px;
    font-size: 4vw;
    color: var(--primary-color);
    font-weight: lighter;
}

.Body-Text{
    padding-top: 2%;
    font-size: 3vw;
    color: var(--primary-color);
}

.SoC-Link{
    color: var(--primary-color);
    text-decoration: none;
    transition:0.2s;
    border-bottom: 1px solid var(--primary-color);
}

.SoC-Link:hover{   
    padding-bottom: 0.3%;
    color: var(--secondary-color);
    border-bottom: 1px solid  var(--secondary-color);
}



/* Phone Sizing */
@media only screen and (max-width: 600px) {

    .Landingpage{
        width:100%;
        height: 100vh;
        background-color: none;
        margin-left: 0%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-right: 0%;
        padding-top: 2%;
        min-height: 800px;
        /* border: 1px solid blue; */
    }
    
    .Heading{
        margin: 0px;
        font-size: 10vw;
        color:  var(--secondary-color);
        font-weight: lighter;
        text-align: center;
    }
    
    .Heading-Developer{
        margin: 0px;
        font-size: 7vw;
        color: var(--primary-color);
        font-weight: lighter;
        text-align: center;
        margin-top: 60%;
    }
    
    .Body-Text{
        padding-top: 2%;
        font-size: 6vw;
        color: var(--primary-color);
        text-align: center;
    }
    
    .SoC-Link{
        color:  var(--secondary-color);
        text-decoration: none;
        transition:0.2s;
        border-bottom: 1px solid  var(--secondary-color);
    }
    
    .SoC-Link:hover{   
        padding-bottom: 0.3%;
        color: var(--secondary-color);
        border-bottom: 1px solid  var(--secondary-color);
    }

 

}