.Footer-container{
    width: 100vw;
    height: 1vh;
    display: flex;
    justify-content: center;
    margin-bottom: 2%;
}

.Footer-text{
    font-weight: bold;
    color: var(--primary-color);
    font-size: small;
}