.ContactPage{
    width: 85%;
    height: 100vh;
    background-color: none;
    margin-top: 45vh;
    margin-left:10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2%;
    min-height: 400px;
    /* border: 1px solid white; */
}

.ContactText-container{
    margin-top: 2%;
    font-size: 4.5vw;
    color: var(--primary-color);
    font-weight: lighter;
    display: flex;
    flex-direction: column;
}

.ContactText{
    text-decoration: none;
    color:var(--primary-color);
    transition: 0.3s;
}

.ContactText:hover{
    color:  var(--secondary-color);
    font-weight: normal;
    padding-left: 1%;
}



/* Phone Sizing */
@media only screen and (max-width: 600px) {
    .ContactPage{
        width: 85%;
        height: 100vh;
        background-color: none;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 800px;
        /* border: 1px solid white; */
    }
    
    .ContactText-container{
        margin-top: 2%;
        font-size: 9vw;
        color: var(--primary-color);
        font-weight: lighter;
        display: flex;
        flex-direction: column;
    }
    
    .ContactText{
        text-decoration: none;
        color:var(--primary-color);
        transition: 0.3s;
    }
    
    .ContactText:hover{
        color:  var(--secondary-color);
        font-weight: normal;
        padding-left: 1%;
    }
}