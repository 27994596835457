.Email-link{
    transition: 0.2s;
    margin-bottom: 0%;
    margin-top: 0%;
    text-decoration: none;
    color: var(--secondary-color);
    border-bottom: 1px solid  var(--secondary-color);
}

.Email-link:hover{
    color:var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
}