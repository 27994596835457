.Header{
    display: none;
}


/* Phone Sizing */
@media only screen and (max-width: 600px) {
    .Header{
        background-color: var(--bg-color);
        opacity: 90%;
        width: 100vw;
        height: var(--Header-height);
        min-height: 35px;
        position: fixed;
        bottom: 0;
        transition: background-color 0.5s;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        z-index: 3;
        border-top: 1px solid var(--primary-color);
    }

    .Header-Button-Container{
        /* border: 1px solid green; */
        width: 30%;
        height: 95%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .Header-Button{
        width: 80%;
        text-align: center;
        align-items: center;
        border: 1px solid var(--primary-color);
        background-color: transparent;
        color: var(--primary-color);
        font-family: inherit;
        font-weight: bold;
        font-size: 3vw;
        padding: 2%;
        text-decoration: none;
        transition: 0.3s;
        border-radius: 5px;
    }

    .Header-Button:hover{
        background-color: var(--primary-color);
        color: var(--bg-color);
    }
}
