.light-mode-button-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    margin-right: 20vw;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    /* border: 1px solid blue; */
    height: 5vh;
    background-color: var(--bg-color);
    z-index: 2;
    transition: 0.5s;
}

.light-mode-button-text{
    opacity: 0%;
    transition: 0.5s;
    font-weight:bold;
    color: var(--secondary-color);
    padding-left: 1%;
    padding-right: 1%;
    cursor: pointer;
}

.light-mode-button-container:hover .light-mode-button-text{
    opacity: 100%;
}

.gg-dark-mode {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    border:2px solid;
    border-radius:100px;
    width:20px;
    height:20px;
    cursor: pointer;
    color: var(--primary-color);
    transition: 0.4s;
}

.gg-dark-mode::after,
.gg-dark-mode::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    display: block
}

.gg-dark-mode::before {
    border:5px solid;
    border-top-left-radius:100px;
    border-bottom-left-radius:100px;
    border-right: 0;
    width:9px;
    height:18px;
    top:-1px;
    left:-1px
}

.gg-dark-mode::after {
    border:4px solid;
    border-top-right-radius:100px;
    border-bottom-right-radius:100px;
    border-left: 0;
    width:4px;
    height:8px;
    right:4px;
    top:4px
}


@media only screen and (max-width: 600px) {
    .light-mode-button-container{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        margin-right: 20vw;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 0;
        /* border: 1px solid blue; */
        height: 5vh;
        background-color: var(--bg-color);
        z-index: 2;
        transition: 0.5s;
    }
}
